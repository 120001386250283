import "ka-table/style.scss";
import React from 'react';
import '../styles.scss';
import Select, { SelectOption } from "@amzn/meridian/select";
import { percentileList, drillDownList } from "./PercentileDrilldownLists";

const percentileWidth = 150;
const drillDownWidth = 200;

interface WorkflowDropdownProps {
  onChangeEvent: (e) => any
  currentValue: string
  workflowType: string
}

const WorkflowDropdown: React.FC<WorkflowDropdownProps> = (props: WorkflowDropdownProps) => {
  const { onChangeEvent, currentValue, workflowType } = props
  let workflowList;
  let width;
  if (workflowType == 'Percentile') {
    workflowList = percentileList;
    width = percentileWidth;
  }
  else if (workflowType == "Drill Down") {
    workflowList = drillDownList;
    width = drillDownWidth;
  }

  return (
    <Select placeholder={workflowType} value={currentValue} onChange={onChangeEvent} width={width} label={workflowType}>
      {

        workflowList.map((option, i) => (
          <SelectOption value={option.value} key={i} label={option.label}></SelectOption>
        ))
      }
    </Select>

  )
}

export default WorkflowDropdown