import "ka-table/style.scss";
import React, { useState } from 'react';

import { ITableProps, kaReducer, Table } from 'ka-table';
import { DataType, PagingPosition, SortingMode } from 'ka-table/enums';
import { DispatchFunc } from 'ka-table/types';
import { search } from 'ka-table/actionCreators';
import { Column } from 'ka-table/models';
import '../styles.scss';
import Toggle from "@amzn/meridian/toggle"
import Row from "@amzn/meridian/row"
import Box from "@amzn/meridian/box"
import WorkflowDate from "../inputFields/SearchDates";
import WorkflowSearchField from "../inputFields/SearchField";

// Temporary static columns for table
const columns: Column[] = [
  { key: 'event', title: 'Event / Constraints', dataType: DataType.String },
  { key: 'user', title: 'User', dataType: DataType.String, filterRowOperator: '>' },
  { key: 'status', title: 'Status', dataType: DataType.String },
  { dataType: DataType.Date, key: 'startDate', title: 'Start', filterRowOperator: '<' },
  { dataType: DataType.Date, key: 'endDate', title: 'End', filterRowOperator: '<' },
  { key: 'details', title: 'Details', dataType: DataType.String },
];


// Temporary data for table
const dataArray = Array(30).fill(undefined).map(
  (_, index) => columns.reduce((previousValue: any, currentValue) => {
    previousValue[currentValue.key] = `${currentValue.key} row:${index}`;
    return previousValue;
  }, { id: index }),
);

// History table with pagination and search
const tablePropsInit: ITableProps = {
  columns,
  data: dataArray,
  search: ({ searchText, rowData, column }) => {
    if (column.key === 'passed') {
      return (searchText === 'false' && !rowData.passed) || (searchText === 'true' && rowData.passed);
    }
  },
  searchText: '',
  paging: {
    enabled: true,
    pageIndex: 0,
    pageSize: 10,
    pageSizes: [5, 10, 20],
    position: PagingPosition.Bottom
  },
  sortingMode: SortingMode.Single,
  rowKeyField: 'id',
};

// History table
const HistoryTable: React.FC = () => {
  const [tableProps, changeTableProps] = useState(tablePropsInit);
  const dispatch: DispatchFunc = (action) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));

  };
  const today = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 7); // 1 week offset
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(lastWeek.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(today.toISOString().split('T')[0]);
  const [systemChecked, setSystemChecked] = useState(false);

  return (
    <>
        <Box spacingInset="Xsmall">
          <Row alignmentVertical="center">
            <WorkflowSearchField setSearchQuery={setSearchQuery} searchQuery={searchQuery} onSubmitEvent={(event) => { dispatch(search(searchQuery)) }}/>
            <WorkflowDate onChangeEvent={setStartDate} setDate={startDate} label={"Start Date"}/>
            <WorkflowDate onChangeEvent={setEndDate} setDate={endDate} label={"End Date"}/>
            <Toggle checked={systemChecked} onChange={setSystemChecked}>System Events</Toggle>
          </Row>
        </Box>
        <Box spacingInset="Xsmall" maxHeight={200} minHeight={200}>

          <Table
            {...tableProps}
            dispatch={dispatch}
            childComponents={{
              headCell: {
                elementAttributes: (props) => {
                  if (props.column.key === 'column0') {
                    return {
                      style: {
                        ...props.column.style,
                        position: 'sticky',
                        left: 0,
                        zIndex: 10,
                      }
                    }
                  }
                }
              },
              cell: {
                elementAttributes: (props) => {
                  if (props.column.key === 'column0') {
                    return {
                      style: {
                        ...props.column.style,
                        position: 'sticky',
                        left: 0,
                        backgroundColor: '#eee',
                      }
                    }
                  }
                }
              }
            }}
          />

        </Box>
    </>
  );
};

export default HistoryTable;