import "ka-table/style.scss";
import React, { useState } from 'react';

import { ITableProps, kaReducer, Table } from 'ka-table';
import { DataType, PagingPosition, SortingMode } from 'ka-table/enums';
import { DispatchFunc } from 'ka-table/types';
import { search } from 'ka-table/actionCreators';
import { Column } from 'ka-table/models';
import '../styles.scss';
import Row from "@amzn/meridian/row"
import Box from "@amzn/meridian/box"
import DrillDownChart from './DrillDownChart'
import Select, { SelectOption } from "@amzn/meridian/select";
import Coachmark from "@amzn/meridian/coachmark"
import Text from "@amzn/meridian/text"
import { kaPropsUtils } from 'ka-table/utils';
import { selectSingleRow } from 'ka-table/actionCreators';
import { percentileList, drillDownList } from "../inputFields/PercentileDrilldownLists";
import WorkflowDropdown from "../inputFields/SearchDropdowns";
import WorkflowDate from "../inputFields/SearchDates";
import WorkflowSearchField from "../inputFields/SearchField";

// Temporary static columns for table
const stickyColumnOffset = 150;
const leftStyleMap = {
  'site': 0,
  'drillDownLevel':stickyColumnOffset,
  'percentile':stickyColumnOffset*2,
};
let firstTimeUsingCoachmark = true;
let columns: Column[] = Array(20).fill(undefined).map(


  (_, index) => ({

    key: 'column' + index,
    width: 150,
    title: 'Column ' + index,
    type: DataType.String,

  }),
);
columns[0].key = 'site';
columns[0].title = 'Site ID';
columns[0].dataType = DataType.String;
columns[1].key = 'drillDownLevel';
columns[1].title = 'F3 Category';
columns[1].dataType = DataType.String;
columns[2].key = 'percentile';
columns[2].title = 'Percentile';
columns[2].dataType = DataType.String;

// Temporary data for table
const dataArray = Array(30).fill(undefined).map(
  (_, index) => columns.reduce((previousValue: any, currentValue) => {
    previousValue[currentValue.key] = `${currentValue.key} row:${index}`;
    return previousValue;
  }, { id: index }),
);

// History table with pagination and search
const tablePropsInit: ITableProps = {
  columns,
  data: dataArray,
  search: ({ searchText, rowData, column }) => {
    if (column.key === 'passed') {
      return (searchText === 'false' && !rowData.passed) || (searchText === 'true' && rowData.passed);
    }
  },
  searchText: '',
  paging: {
    enabled: true,
    pageIndex: 0,
    pageSize: 20,
    pageSizes: [10, 20, 50],
    position: PagingPosition.Bottom
  },
  sortingMode: SortingMode.Single,
  rowKeyField: 'id',
};

var forecastList = [
  { label: "1:00 AM", value: "time1" },
  { label: "9:52 AM", value: "time2" },
  { label: "3:44 PM", value: "time3" },
]

const DrillDownTable: React.FC = () => {
  //const {drilldown, id} = useParams<{drilldown: string, id: string}>();
  const [tableProps, changeTableProps] = useState(tablePropsInit);
  const selectedData = kaPropsUtils.getSelectedData(tableProps).pop();
  const dispatch: DispatchFunc = (action) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
  };

  const today = new Date();
  today.setDate(today.getDate() - 5); // 5 day offset, shows forecasts generated 5 days before
  const [searchQuery, setSearchQuery] = useState("");
  const [runDate, setRunDate] = useState(today.toISOString().split('T')[0]);
  const [percentile, setPercentile] = useState(percentileList[0].value);
  const [drillDown, setDrillDown] = useState(drillDownList[0].value);
  const [multiForecast, setMultiForecast] = useState(forecastList[0].value);


  const ForecastButton = () => {
    //TODO Make this dynamic for all forecasts generated on the same day
    if (runDate === "2022-07-12") {
      if (firstTimeUsingCoachmark) {
        firstTimeUsingCoachmark = false;
        return (
          <>
            <Coachmark popoverPosition="top">
              <Text>There were multiple forecasts generated on this date!</Text>
            </Coachmark>

            <Select placeholder="Latest Forecast" value={multiForecast} onChange={setMultiForecast} width={200} label="Select Forecast">
              {
                forecastList.map((option, i) => (
                  <SelectOption value={option.value} key={i} label={option.label}></SelectOption>
                ))
              }
            </Select>
          </>
        );
      }
      else {
        return (
          <>
            <Coachmark popoverPosition="top" animating={false}>
              <Text>This Date has multiple forecasts!</Text>
            </Coachmark>

            <Select placeholder="Latest Forecast" value={multiForecast} onChange={setMultiForecast} width={200} label="Select Forecast">
              {
                forecastList.map((option, i) => (
                  <SelectOption value={option.value} key={i} label={option.label}></SelectOption>
                ))
              }
            </Select>
          </>
        );
      }


    }
    return (<></>);
  }
  


  return (
    <>

        <Box spacingInset="xsmall">
          <Row alignmentVertical="center" alignmentHorizontal="center">
          <WorkflowSearchField setSearchQuery={setSearchQuery} searchQuery={searchQuery} onSubmitEvent={(event) => { dispatch(search(searchQuery)) }}/>
            <WorkflowDate onChangeEvent={setRunDate} setDate={runDate} label={"Run Date"}/>
            <WorkflowDropdown onChangeEvent = {setDrillDown} currentValue = {drillDown} workflowType = {"Drill Down"}/>
            <WorkflowDropdown onChangeEvent = {setPercentile} currentValue = {percentile} workflowType= {"Percentile"}/>
            <ForecastButton></ForecastButton>
          </Row>
        </Box>
        <DrillDownChart></DrillDownChart>
        <Box spacingInset="xsmall" maxHeight={200} minHeight={200}>

          <Table
            {...tableProps}
            dispatch={dispatch}
            childComponents={{
              headCell: {
                elementAttributes: (props) => {
                  if (props.column.key === 'site') {
                    return {
                      style: {
                        ...props.column.style,
                        position: 'sticky',
                        left: leftStyleMap[props.column.key],
                        zIndex: 10,
                      }
                    }
                  }
                }
              },
              cell: {
                elementAttributes: (props) => {
                  if (props.column.key === 'site') {
                    return {
                      style: {
                        ...props.column.style,
                        position: 'sticky',
                        left: leftStyleMap[props.column.key],
                        backgroundColor: '#eee',
                      }
                    }
                  }
                }
              },
              dataRow: {
                elementAttributes: () => ({
                  onClick: (event, extendedEvent) => {
                    extendedEvent.dispatch(selectSingleRow(extendedEvent.childProps.rowKeyValue));
                  },
                })
              },

            }}
          />


        </Box>
    </>
  );
};

export default DrillDownTable;