import HistoryTable from './HistoryTable';
import React from 'react';
import Box from "@amzn/meridian/box"

// The content in the main content area of the App layout
export default function HistoryPage() {
  return (
    <>
    <Box spacingInset="Medium" maxHeight={200} minHeight={200}>
      <HistoryTable></HistoryTable>
      </Box>
    </>
  );
}
