import "ka-table/style.scss";
import React from 'react';
import '../styles.scss';
import DatePicker from "@amzn/meridian/date-picker"

const width = 300;
interface WorkflowDateProps {
  onChangeEvent: (e) => any
  setDate: string
  label: string
}


const WorkflowDate: React.FC<WorkflowDateProps> = (props: WorkflowDateProps) => {
  const { onChangeEvent, setDate, label } = props
  return (
    <DatePicker value={setDate} onChange={onChangeEvent} width={width} label={label} />
  )
}

export default WorkflowDate