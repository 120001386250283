import {
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryLine,
  TextSize,
} from "victory"
import Legend, { LegendProvider } from "@amzn/meridian/legend"
import victoryLinePlugin from "@amzn/meridian/legend/plugin/victory-line"
import victoryTooltipPlugin from "@amzn/meridian/legend/plugin/victory-tooltip"
import useVictoryTheme from "@amzn/meridian/use-victory-theme"
import "../styles.scss"
import React from 'react';

export default function LandingChart() {

  const formatLabel = ({ datum }) => datum.value
  const theme = useVictoryTheme({ showIndependentGrid: false, })

  const datasets = [
    {
      key: "forecasted",
      label: "Forecasted",
      data: [
        { year: "2010", value: 50 },
        { year: "2011", value: 200 },
        { year: "2012", value: 40 },
        { year: "2013", value: 3 },
        { year: "2014", value: 40 },
        { year: "2015", value: 100 },
        { year: "2016", value: 50 },
        { year: "2017", value: 200 },
        { year: "2018", value: 40 },
        { year: "2019", value: 3 },
        { year: "2020", value: 40 },
        { year: "2021", value: 100 },
      ],
    },
    {
      key: "actuals",
      label: "Actuals",
      data: [
        { year: "2010", value: 2 },
        { year: "2011", value: 30 },
        { year: "2012", value: 200 },
        { year: "2013", value: 40 },
        { year: "2014", value: 300 },
        { year: "2015", value: 50 },
        { year: "2016", value: 2 },
        { year: "2017", value: 30 },
        { year: "2018", value: 200 },
        { year: "2019", value: 40 },
        { year: "2020", value: 300 },
        { year: "2021", value: 50 },
      ],
    }
  ]
  return (
    <>
      <div id="landingChart">
        <LegendProvider
          data={datasets}
          plugins={[
            victoryLinePlugin({ theme }),
            victoryTooltipPlugin({ showCrosshair: true }),
          ]}
          aria-label="Line chart graphing the number of animals per year"
        >
          {({ getLineProps, voronoiContainerProps, Tooltip }) => (
            <div>
              <Legend direction="horizontal" />
              <VictoryChart
                padding={20}
                width={600}
                height={100}
                domainPadding={{ x: [0, 10], y: [0, 10] }}
                name="line-chart-animals-per-year"
                containerComponent={
                  <VictoryVoronoiContainer
                    labels={formatLabel}
                    {...voronoiContainerProps}
                  />
                }
              >
                <VictoryAxis style={{ tickLabels: { fontSize: 5 } }} />
                <VictoryAxis
                  dependentAxis
                  style={{ tickLabels: { fontSize: 5 } }}
                  tickFormat={(value, index) => (index > 0 ? value : "")}
                />
                {datasets.map(({ data, key }) => (
                  <VictoryLine
                    key={key}
                    data={data}
                    x="year"
                    y="value"
                    style={{ data: { stroke: "#c43a31", strokeWidth: 5, strokeLinecap: "round" } }}
                    labels={formatLabel}
                    labelComponent={<Tooltip legendKey={key} />}
                    {...getLineProps(key)}
                  />
                ))}

              </VictoryChart>
            </div>
          )}
        </LegendProvider>
      </div>
    </>
  );
}