import "ka-table/style.scss";
import React from 'react';

import '../styles.scss';
import SearchField from "@amzn/meridian/search-field"

interface WorkflowSearchFieldProps {
  setSearchQuery: (e) => any
  searchQuery: string
  onSubmitEvent: (e) => any
}

const WorkflowSearchField: React.FC<WorkflowSearchFieldProps> = (props: WorkflowSearchFieldProps) => {
  const { setSearchQuery, searchQuery, onSubmitEvent } = props


  return (
    <SearchField
      value={searchQuery}
      onChange={setSearchQuery}
      placeholder="Search for..."
      onSubmit={onSubmitEvent}
      width={300}
      size="xlarge"
      label="Search"
    />

  )
}

export default WorkflowSearchField