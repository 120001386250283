/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Petstore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface Pet
 */
export interface Pet {
    /**
     * 
     * @type {number}
     * @memberof Pet
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'tag'?: string;
}

/**
 * PetsApi - axios parameter creator
 * @export
 */
export const PetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a pet
         * @param {Pet} pet A *Pet* to be added
         * @param {*} [options] Override http request option.
         * @param {String} callAPIURL 
         * @throws {RequiredError}
         */
        createPets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pet' is not null or undefined
            //assertParamExists('createPets', 'pet', pet)
            //const localVarPath = `/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            //const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            const localVarUrlObj = new URL("https://4n5jg658za.execute-api.us-east-1.amazonaws.com/test/anonymous-embed");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            //const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options,};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            //localVarRequestOptions.data = serializeDataIfNeeded(pet, localVarRequestOptions, configuration)
            localVarRequestOptions.data = serializeDataIfNeeded(localVarRequestOptions, configuration)
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
                
            };
        },
        /**
         * 
         * @summary Delete Pet by id
         * @param {number} petId The id of the pet to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePetById: async (petId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('deletePetById', 'petId', petId)
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all pets
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPets: async (limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific pet
         * @param {number} petId The id of the pet to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPetById: async (petId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('showPetById', 'petId', petId)
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PetsApi - functional programming interface
 * @export
 */
export const PetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a pet
         * @param {Pet} pet A *Pet* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        //async createPets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            async createPets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.createPets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Pet by id
         * @param {number} petId The id of the pet to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePetById(petId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePetById(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all pets
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPets(limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPets(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific pet
         * @param {number} petId The id of the pet to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showPetById(petId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showPetById(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PetsApi - factory interface
 * @export
 */
export const PetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PetsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a pet
         * @param {Pet} pet A *Pet* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPets(options?: any): AxiosPromise<void> {
            return localVarFp.createPets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Pet by id
         * @param {number} petId The id of the pet to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePetById(petId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePetById(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all pets
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPets(limit?: number, options?: any): AxiosPromise<Array<Pet>> {
            return localVarFp.listPets(limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific pet
         * @param {number} petId The id of the pet to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPetById(petId: number, options?: any): AxiosPromise<Pet> {
            return localVarFp.showPetById(petId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PetsApi - object-oriented interface
 * @export
 * @class PetsApi
 * @extends {BaseAPI}
 */
export class PetsApi extends BaseAPI {
    /**
     * 
     * @summary Create a pet
     * @param {Pet} pet A *Pet* to be added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetsApi
     */
    public createPets(options?: AxiosRequestConfig) {
        return PetsApiFp(this.configuration).createPets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Pet by id
     * @param {number} petId The id of the pet to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetsApi
     */
    public deletePetById(petId: number, options?: AxiosRequestConfig) {
        return PetsApiFp(this.configuration).deletePetById(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all pets
     * @param {number} [limit] How many items to return at one time (max 100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetsApi
     */
    public listPets(limit?: number, options?: AxiosRequestConfig) {
        return PetsApiFp(this.configuration).listPets(limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific pet
     * @param {number} petId The id of the pet to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetsApi
     */
    public showPetById(petId: number, options?: AxiosRequestConfig) {
        return PetsApiFp(this.configuration).showPetById(petId, options).then((request) => request(this.axios, this.basePath));
    }
}


