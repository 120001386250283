import { useHistory, useParams } from "react-router-dom";
import DrillDownTable from "./DrillDownTable";
import React from "react";
import Box from "@amzn/meridian/box"

export default function DrillDownPage() {
  const history = useHistory();
  const { drilldown, id } = useParams<{ drilldown: string, id: string }>();
  return (
    <>
    <Box spacingInset="Medium" maxHeight={200} minHeight={200}>
      <DrillDownTable></DrillDownTable>
      </Box>
    </>
  );
}
