import React from 'react';
import LandingTable from './LandingTable';
import Box from "@amzn/meridian/box";
// The content in the main content area of the App layout
export default function HistoryPage() {
  return (
    <>
    <Box spacingInset="medium" maxHeight={200} minHeight={200}>
      <LandingTable></LandingTable>
      </Box>
    </>
  );
}
