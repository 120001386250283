import { useHistory } from "react-router-dom";
import React, { useState } from 'react';
import Button from "@amzn/meridian/button";
import Select, { SelectOption } from "@amzn/meridian/select";
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";

export default function BusinessSelectionPage() {

  // list of Business options
  let businessList = [
    { label: "UFF", value: "UFF" },
    { label: "AFS", value: "AFS" },
  ]
  // list of Region options
  let regionList = [
    { label: "NA", value: "NA" },
    { label: "EU", value: "EU" },
  ]
  // list of Country options
  let countryList = [
    { label: "USA", value: "USA" },
    { label: "UK", value: "UK" },
  ]
  // list of ModelType options
  let modelList = [
    { label: "BOSCO", value: "BOSCO" },
    { label: "Prospero", value: "Prospero" },
  ]

  // Update states for selection
  const [business, setBusiness] = useState();
  const [region, setRegion] = useState();
  const [country, setCountry] = useState();
  const [model, setModel] = useState();



  // Listener to enter portal on click and update history
  const history = useHistory();
  function enterPortal() {
    console.log("Settings: ", business, " ", region, " ", country, " ", model);
    history.push(`/landing`);
  }

  //Business dropdown (will change size when selected, add animation later)
  function BusinessDropdown() {
    return (
      <Select placeholder="Business" value={business} onChange={setBusiness} width={300} label="Business">
        {
          businessList.map((option, i) => (
            <SelectOption value={option.value} key={i} label={option.label}></SelectOption>
          ))
        }
      </Select>
    );
  }

  // Region Dropdown
  function RegionDropdown() {
    return (
      <Select placeholder="Region" value={region} onChange={setRegion} width={300} label="Region">
        {
          regionList.map((option, i) => (
            <SelectOption value={option.value} key={i} label={option.label}></SelectOption>
          ))
        }
      </Select>
    );
  }

  // Country Dropdown
  function CountryDropdown() {
    return (
      <Select placeholder="Country" value={country} onChange={setCountry} width={300} label="Country">
        {
          countryList.map((option, i) => (
            <SelectOption value={option.value} key={i} label={option.label}></SelectOption>
          ))
        }
      </Select>
    );
  }

  // Model Dropdown
  function ModelDropdown() {
    return (
      <Select placeholder="Model ID" value={model} onChange={setModel} width={300} label="Model ID">
        {
          modelList.map((option, i) => (
            <SelectOption value={option.value} key={i} label={option.label}></SelectOption>
          ))
        }
      </Select>
    );
  }

  // Submit button
  function SubmitButton() {
    if (business && country && region && model) {
      return <Button type="tertiary" onClick={() => enterPortal()}>Enter Portal</Button>
    }
    else {
      return <Button type="tertiary" disabled onClick={() => enterPortal()}>Enter Portal</Button>
    }
  }

  return (
    <>
      <Row alignmentHorizontal="center">
        <Box spacingInset="Xlarge">
          <Box spacingInset="small">
            <BusinessDropdown></BusinessDropdown>
          </Box>
          <Box spacingInset="small">
            <RegionDropdown></RegionDropdown>
          </Box>
          <Box spacingInset="small">
            <CountryDropdown></CountryDropdown>
          </Box>
          <Box spacingInset="small">
            <ModelDropdown></ModelDropdown>
          </Box>
          <Box spacingInset="medium">
            <Row alignmentHorizontal="center">
              <SubmitButton></SubmitButton>
            </Row>
          </Box>
        </Box>
      </Row>
    </>
  );
}
