import React from 'react';
import Row from "@amzn/meridian/row";
import ComponentDidMount from './quicksightBosco';

export default function QuicksightPage() {

  return (

    <>
      <ComponentDidMount></ComponentDidMount>
      <Row alignmentHorizontal="center">
        <div id="embeddingContainer"></div>
      </Row>

    </>
  );
}
